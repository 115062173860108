<template>
  <v-card elevation="4" shaped outlined class="my-2">
    <v-card-text>
      <v-row>
        <v-col sm="12" md="7" class="mx-auto">
          <v-card outlined>
            <v-card-title>Youngest borrower</v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="6" sm="12">
                  <v-text-field
                    v-model="forms.youngestBorrower.firstName"
                    :error="!!errors.youngestBorrower.firstName"
                    :error-messages="errors.youngestBorrower.firstName"
                    maxlength="50"
                    label="FIRST NAME*"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="6" sm="12">
                  <v-text-field
                    v-model="forms.youngestBorrower.lastName"
                    :error="!!errors.youngestBorrower.lastName"
                    :error-messages="errors.youngestBorrower.lastName"
                    maxlength="50"
                    label="LAST NAME*"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-text-field
                v-model="forms.youngestBorrower.email"
                :error="!!errors.youngestBorrower.email"
                :error-messages="errors.youngestBorrower.email"
                label="EMAIL*"
                outlined
              ></v-text-field>
              <v-row>
                <v-col md="6" sm="12">
                  <DatePicker
                    label="DATE OF BIRTH*"
                    :max="getMaxDate()"
                    min="1920-01-01"
                    v-model="forms.youngestBorrower.dateOfBirth"
                  />
                </v-col>
                <v-col md="6" sm="12">
                  <v-text-field
                    v-model="forms.youngestBorrower.phone"
                    :error="!!errors.youngestBorrower.phone"
                    :error-messages="errors.youngestBorrower.phone"
                    maxlength="17"
                    label="PHONE NUMBER*"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-text-field
                v-model="forms.youngestBorrower.address1"
                :error="!!errors.youngestBorrower.address1"
                :error-messages="errors.youngestBorrower.address1"
                maxlength="200"
                label="ADDRESS 1*"
                outlined
              ></v-text-field>

              <v-text-field
                v-model="forms.youngestBorrower.address2"
                :error="!!errors.youngestBorrower.address2"
                :error-messages="errors.youngestBorrower.address2"
                maxlength="200"
                label="ADDRESS 2"
                outlined
              ></v-text-field>

              <v-row>
                <v-col md="6" sm="12">
                  <v-text-field
                    v-model="forms.youngestBorrower.city"
                    :error="!!errors.youngestBorrower.city"
                    :error-messages="errors.youngestBorrower.city"
                    maxlength="50"
                    label="CITY*"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="6" sm="12">
                  <v-text-field
                    v-model="forms.youngestBorrower.state"
                    :error="!!errors.youngestBorrower.state"
                    :error-messages="errors.youngestBorrower.state"
                    maxlength="50"
                    label="STATE*"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-text-field
                v-model="forms.youngestBorrower.zip"
                :error="!!errors.youngestBorrower.zip"
                :error-messages="errors.youngestBorrower.zip"
                maxlength="10"
                label="ZIP CODE*"
                outlined
              ></v-text-field>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="hidden-sm-and-down" md="5" v-if="currentScenarioTitle">
          <v-card outlined>
            <v-card-title>Oldest borrower (optional)</v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="6" sm="12">
                  <v-text-field
                    v-model="forms.oldestBorrower.firstName"
                    :error="!!errors.oldestBorrower.firstName"
                    :error-messages="errors.oldestBorrower.firstName"
                    maxlength="50"
                    label="FIRST NAME"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="6" sm="12">
                  <v-text-field
                    v-model="forms.oldestBorrower.lastName"
                    :error="!!errors.oldestBorrower.lastName"
                    :error-messages="errors.oldestBorrower.lastName"
                    maxlength="50"
                    label="LAST NAME"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-text-field
                v-model="forms.oldestBorrower.email"
                :error="!!errors.oldestBorrower.email"
                :error-messages="errors.oldestBorrower.email"
                label="EMAIL"
                outlined
              ></v-text-field>
              <v-row>
                <v-col md="6" sm="12">
                  <DatePicker
                    label="DATE OF BIRTH"
                    :max="getMaxDate()"
                    min="1920-01-01"
                    v-model="forms.oldestBorrower.dateOfBirth"
                  />
                </v-col>
                <v-col md="6" sm="12">
                  <v-text-field
                    v-model="forms.oldestBorrower.phone"
                    :error="!!errors.oldestBorrower.phone"
                    :error-messages="errors.oldestBorrower.phone"
                    maxlength="17"
                    label="PHONE NUMBER"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-text-field
                v-model="forms.oldestBorrower.address1"
                :error="!!errors.oldestBorrower.address1"
                :error-messages="errors.oldestBorrower.address1"
                maxlength="200"
                label="ADDRESS 1"
                outlined
              ></v-text-field>

              <v-text-field
                v-model="forms.oldestBorrower.address2"
                :error="!!errors.oldestBorrower.address2"
                :error-messages="errors.oldestBorrower.address2"
                maxlength="200"
                label="ADDRESS 2"
                outlined
              ></v-text-field>

              <v-row>
                <v-col md="6" sm="12">
                  <v-text-field
                    v-model="forms.oldestBorrower.city"
                    :error="!!errors.oldestBorrower.city"
                    :error-messages="errors.oldestBorrower.city"
                    maxlength="50"
                    label="CITY"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="6" sm="12">
                  <v-text-field
                    v-model="forms.oldestBorrower.state"
                    :error="!!errors.oldestBorrower.state"
                    :error-messages="errors.oldestBorrower.state"
                    maxlength="50"
                    label="STATE"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-text-field
                v-model="forms.oldestBorrower.zip"
                :error="!!errors.oldestBorrower.zip"
                :error-messages="errors.oldestBorrower.zip"
                maxlength="10"
                label="ZIP CODE"
                outlined
              ></v-text-field>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="currentScenarioTitle">
        <v-col>
          <v-textarea
            outlined
            maxlength="500"
            auto-grow
            rows="4"
            label="MAIN OBJECTIVE"
            placeholder="Line of credit, end current mortgage payment, receive monthly payments, etc."
            v-model="forms.mainObjective"
          ></v-textarea>
        </v-col>
      </v-row>
      <div class="d-flex justify-center my-2">
        <div class="submit-btn-container">
          <v-btn block x-large color="success" :loading="loading" @click="handleSubmit">
            Continue
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment';
import validate from 'validate.js';
import {
  youngestBorrower as youngestBorrowerSchema,
  oldestBorrower as oldestBorrowerSchema
} from './validation';

import DatePicker from './DatePicker.vue';

const getDefaultBorrower = () => ({
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  email: '',
  phone: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: ''
});

const getDefaultState = () => ({
  loading: false,
  forms: {
    youngestBorrower: getDefaultBorrower(),
    oldestBorrower: getDefaultBorrower(),
    mainObjective: ''
  },
  errors: {
    youngestBorrower: {},
    oldestBorrower: {}
  }
});

function formatUSNumber(entry = '') {
  const match = entry
    .replace(/\D+/g, '')
    .replace(/^1/, '')
    .match(/([^\d]*\d[^\d]*){1,10}$/)[0];
  const part1 = match.length > 2 ? `(${match.substring(0, 3)})` : match;
  const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : '';
  const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : '';
  return `+1 ${part1}${part2}${part3}`;
}

export default {
  components: {
    DatePicker
  },
  data: () => getDefaultState(),
  methods: {
    getMaxDate() {
      return moment().subtract(18, 'years').format('YYYY-MM-DD');
    },
    validate() {
      const youngestBorrowerErrors = validate(
        this.$data.forms.youngestBorrower,
        youngestBorrowerSchema
      );
      const oldestBorrowerErrors = validate(this.$data.forms.oldestBorrower, oldestBorrowerSchema);

      if (youngestBorrowerErrors) {
        this.$data.errors.youngestBorrower = youngestBorrowerErrors;
      }
      if (oldestBorrowerErrors) {
        this.$data.errors.oldestBorrower = oldestBorrowerErrors;
      }
      if (youngestBorrowerErrors || oldestBorrowerErrors) return false;
      return true;
    },

    async createLoanInQR() {
      await this.$store.dispatch('getNewScenarioData', true);
      await this.$store.dispatch('createLoanInQR');
    },

    async handleSubmit() {
      const isFormsValid = this.validate();
      if (!isFormsValid) return;

      this.$store.dispatch('setLeadCaptureForm', this.$data.forms);

      try {
        this.loading = true;

        if (
          !this.lenderLoanNumber &&
          this.currentScenarioTitle === this.allScenariosTitles.scenarioETitle
        ) {
          await this.createLoanInQR();
        }

        await this.$store.dispatch('postResultsToLO');

        this.$toast.success('Please check your email for a message from us.', {
          onClose: () => {
            this.$router.push({
              name: 'Saved Result',
              params: {
                resultUID: this.resultUID
              }
            });
          }
        });
      } catch (error) {
        console.error(error);
        this.$toast.error('Failed to send', {
          onClose: () => {
            this.$router.push({
              name: 'Saved Result',
              params: {
                resultUID: this.resultUID
              }
            });
          }
        });
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    '$data.forms.youngestBorrower.phone': function (value, prevValue) {
      if (prevValue !== value) {
        this.$data.forms.youngestBorrower.phone = formatUSNumber(value);
      }
    },
    '$data.forms.oldestBorrower.phone': function (value, prevValue) {
      if (prevValue !== value) {
        this.$data.forms.oldestBorrower.phone = formatUSNumber(value);
      }
    }
  },
  computed: {
    resultUID() {
      return this.$store.state.scenarioData.resultUID;
    },
    lenderLoanNumber() {
      return this.$store.state.scenarioData.qrData.response?.Identification?.LenderLoanNumber;
    },
    currentScenarioTitle() {
      return this.$store.state.scenarioData.scenario;
    },
    allScenariosTitles() {
      return this.$store.state.scenarioData.allScenarioTitles;
    }
  }
};
</script>

<style scoped>
.submit-btn-container {
  max-width: 400px;
  width: -webkit-fill-available;
}

.v-label {
  color: rgb(156, 50, 50) !important;
  font-weight: 500 !important;
}
</style>
