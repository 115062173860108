export const youngestBorrower = {
  firstName: {
    presence: {
      allowEmpty: false
    },
    type: 'string'
  },
  lastName: {
    presence: {
      allowEmpty: false
    },
    type: 'string'
  },
  dateOfBirth: {
    presence: {
      allowEmpty: false
    },
    type: 'string'
  },
  email: {
    presence: {
      allowEmpty: false
    },
    email: true,
    type: 'string'
  },
  phone: {
    presence: {
      allowEmpty: false
    },
    length: { is: 17, notValid: 'is not valid', wrongLength: 'is not valid' },
    type: 'string'
  },
  address1: {
    presence: {
      allowEmpty: false
    },
    type: 'string'
  },
  address2: {
    presence: {
      allowEmpty: true
    },
    type: 'string'
  },
  city: {
    presence: {
      allowEmpty: false
    },
    type: 'string'
  },
  state: {
    presence: {
      allowEmpty: false
    },
    type: 'string'
  },
  zip: {
    presence: {
      allowEmpty: false
    },
    type: 'string'
  }
};

export const oldestBorrower = {
  firstName: {
    presence: {
      allowEmpty: true
    },
    type: 'string'
  },
  lastName: {
    presence: {
      allowEmpty: true
    },
    type: 'string'
  },
  dateOfBirth: {
    presence: {
      allowEmpty: true
    },
    type: 'string'
  },
  email: {
    presence: {
      allowEmpty: true
    },
    type: 'string'
  },
  phone: {
    presence: {
      allowEmpty: true
    },
    type: 'string'
  },
  address1: {
    presence: {
      allowEmpty: true
    },
    type: 'string'
  },
  address2: {
    presence: {
      allowEmpty: true
    },
    type: 'string'
  },
  city: {
    presence: {
      allowEmpty: true
    },
    type: 'string'
  },
  state: {
    presence: {
      allowEmpty: true
    },
    type: 'string'
  },
  zip: {
    presence: {
      allowEmpty: true
    },
    type: 'string'
  }
};
