<template>
  <div class="lead-capture-container">
    <div>
      <v-row>
        <v-col xs="12" sm="12" md="9">
          <div class="d-flex flex-column items-center pr-8">
            <p class="text-h5">Please enter your personal information below.</p>

            <p v-if="currentScenarioTitle" class="text-b2 text-justify">
              We will then send you a short email to confirm we have the correct email address.
              After you click on the validation link, we will send you a PDF of the scenario you
              built on our site, and a Reverse Mortgage Proposal, including estimated interest rates
              and fees. Scroll down for our contact information and important disclosures.
            </p>
            <p v-if="!currentScenarioTitle" class="text-b2 text-justify">
              Loan Officer will review your scenario and report back to you.
            </p>
          </div>
        </v-col>
        <v-col v-if="currentScenarioTitle" class="d-flex justify-center align-center">
          <IAmNotReadyButton />
        </v-col>
      </v-row>

      <LeadCaptureForm />
    </div>
    <LeadCaptureDescription v-if="currentScenarioTitle" />

    <Footer />
  </div>
</template>

<script>
import LeadCaptureForm from '@/components/LeadCapture/Form/index.vue';
import LeadCaptureDescription from '@/components/LeadCapture/Description/index.vue';
import Footer from '@/components/LeadCapture/Footer.vue';
import IAmNotReadyButton from '@/components/LeadCapture/IAmNotReadyButton';

export default {
  components: {
    LeadCaptureForm,
    LeadCaptureDescription,
    Footer,
    IAmNotReadyButton
  },
  computed: {
    currentScenarioTitle() {
      return this.$store.state.scenarioData.scenario;
    }
  }
};
</script>

<style scoped>
.lead-capture-container {
  max-width: 1200px;
  margin: auto;
}
.text-b2 {
  font-size: 1.2rem;
  line-height: normal;
}

.form-card {
  max-width: 1400px;
  box-shadow: 0px 0px 10px #e9ebfc;
}

.headline {
  max-width: 900px;
  margin: 0 auto;
}
</style>
