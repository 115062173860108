<template>
  <v-btn
    depressed
    rounded
    color="blue lighten-5"
    class="blue--text darken-5 align-self-center"
    style="height: 100px"
    @click="downloadScenario"
    x-large
    :loading="isLoading"
  >
    <div style="white-space: break-spaces">
      {{ buttonText }}
    </div>
  </v-btn>
</template>

<script>
import moment from 'moment';

import { userAxios } from '@/axios';
import { downloadFile } from '@/helpers/download';

export default {
  data() {
    return {
      isLoading: false,
      buttonText:
        'I am not ready to give \n  personal information yet,\n but I want to download \n the scenario I just built'
    };
  },
  computed: {
    loanOfficerUID() {
      return this.$store.state.loanOfficer.uid;
    },
    resultId() {
      return this.$store.state.scenarioData.resultId;
    }
  },
  methods: {
    async downloadScenario() {
      this.$data.isLoading = true;
      await this.$store.dispatch('postResultsToLO');
      await this.downloadPDF();
      this.$data.isLoading = false;
    },
    async downloadPDF() {
      try {
        const pdfBLOB = await userAxios.get(`public/calculation-results/${this.resultId}/pdf`, {
          responseType: 'blob',
          timeout: null
        });

        downloadFile(pdfBLOB.data, `calculation-result.${moment().format('DD/MM/YYYY')}.pdf`);
      } catch (error) {
        console.error(error);
        this.$toast.error('Failed to download');
      }
    }
  }
};
</script>
