export function downloadFile(fileBlob, filename) {
  const pdfURL = URL.createObjectURL(fileBlob);

  const anchor = document.createElement('a');
  anchor.href = pdfURL;
  anchor.download = filename;

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);

  URL.revokeObjectURL(pdfURL);

  return { done: true };
}
